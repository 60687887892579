var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-8 rounded-lg box-card-shadow",attrs:{"outlined":""}},[_c('div',{staticClass:"text-h3 text-md-h2 font-weight-bold mb-8"},[_vm._v(" Solicita tu cotización ")]),_c('div',[_c('v-form',{attrs:{"id":"request-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._l((_vm.quotationRequestItems),function(quotationItem,i){return _c('div',{key:i},[(quotationItem.type == 'textfield')?_c('v-text-field',{staticClass:"rounded-lg mb-3 mb-md-6 v-input--is-focused",style:([
            quotationItem.model && {
              'background-color': 'rgba(98, 37, 130, 0.1) !important',
            } ]),attrs:{"flat":"","dense":"","outlined":"","required":"","hide-details":"","single-line":"","autocomplete":"null","color":"secondary","background-color":"transparent","placeholder":quotationItem.text},model:{value:(quotationItem.model),callback:function ($$v) {_vm.$set(quotationItem, "model", $$v)},expression:"quotationItem.model"}}):_vm._e(),(quotationItem.type == 'select')?_c('v-autocomplete',{staticClass:"mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1",attrs:{"items":quotationItem.options,"no-data-text":quotationItem.noDataText,"placeholder":quotationItem.text,"auto-select-first":"","single-line":"","outlined":"","solo":"","dense":"","flat":"","hide-details":"","color":"secondary","item-color":"secondary","background-color":quotationItem.model ? 'rgba(98, 37, 130, 0.1)' : '',"autocomplete":"null","menu-props":{
            bottom: true,
            closeOnContentClick: true,
            allowOverflow: true,
            offsetY: true,
            rounded: 'md',
            transition: 'slide-y-transition',
          },"append-icon":"fa-caret-down secondary--text"},model:{value:(quotationItem.model),callback:function ($$v) {_vm.$set(quotationItem, "model", $$v)},expression:"quotationItem.model"}}):_vm._e(),(quotationItem.type == 'datePicker')?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg mb-3 mb-md-6 v-input--is-focused",style:([
                quotationItem.model && {
                  'background-color': 'rgba(98, 37, 130, 0.1) !important',
                } ]),attrs:{"readonly":"","outlined":"","dense":"","clearable":"","clear-icon":"fa-times secondary--text","placeholder":"Fecha del evento","hide-details":""},model:{value:(quotationItem.model),callback:function ($$v) {_vm.$set(quotationItem, "model", $$v)},expression:"quotationItem.model"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(quotationItem.modelMenu),callback:function ($$v) {_vm.$set(quotationItem, "modelMenu", $$v)},expression:"quotationItem.modelMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","value":"date","locale":"es-ES","min":_vm.newUnixDay},on:{"input":function($event){quotationItem.modelMenu = false}},model:{value:(quotationItem.model),callback:function ($$v) {_vm.$set(quotationItem, "model", $$v)},expression:"quotationItem.model"}})],1):_vm._e(),(quotationItem.type == 'hour-textfield')?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2 pr-sm-3 pr-md-0",attrs:{"cols":"7"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],staticClass:"rounded-lg mb-3 mb-md-6 v-input--is-focused",style:([
                quotationItem.modelHour && {
                  'background-color': 'rgba(98, 37, 130, 0.1) !important',
                } ]),attrs:{"flat":"","dense":"","outlined":"","required":"","hide-details":"","single-line":"","type":"text","color":"secondary","background-color":"transparent","placeholder":quotationItem.textHour},model:{value:(quotationItem.modelHour),callback:function ($$v) {_vm.$set(quotationItem, "modelHour", $$v)},expression:"quotationItem.modelHour"}})],1),_c('v-col',{staticClass:"pl-0 pl-md-3",attrs:{"cols":"5"}},[_c('v-select',{staticClass:"mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1",attrs:{"items":quotationItem.options,"append-icon":"fa-caret-down secondary--text","hide-details":"","single-line":"","outlined":"","solo":"","dense":"","flat":"","color":"secondary","item-color":"secondary","background-color":quotationItem.modelTime ? 'rgba(98, 37, 130, 0.1)' : '',"menu-props":{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              },"placeholder":quotationItem.textTime},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(quotationItem.modelTime),callback:function ($$v) {_vm.$set(quotationItem, "modelTime", $$v)},expression:"quotationItem.modelTime"}})],1)],1):_vm._e(),(quotationItem.type == 'textarea')?_c('v-textarea',{staticClass:"rounded-lg mb-3 v-input--is-focused",style:([
            quotationItem.model && {
              'background-color': 'rgba(98, 37, 130, 0.1) !important',
            } ]),attrs:{"solo":"","flat":"","dense":"","no-resize":"","outlined":"","required":"","hide-details":"","rows":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm ? 4 : 5,"color":"secondary","row-height":"25","background-color":"transparent","maxlength":"255","placeholder":quotationItem.text},model:{value:(quotationItem.model),callback:function ($$v) {_vm.$set(quotationItem, "model", $$v)},expression:"quotationItem.model"}}):_vm._e()],1)}),_c('Button',{staticClass:"my-7",attrs:{"text":"Solicitar","block":"","aria-label":"Abrir ventana del registro de usuario"},on:{"event":function($event){return _vm.login()}}})],2)],1),_c('ModalLoginRegister',{attrs:{"dialog":_vm.dialog,"modal-type":_vm.modalType,"redirect-login":_vm.redirectLogin,"redirect-register":_vm.redirectRegister},on:{"closeModal":_vm.handleModal,"hanldeModalType":_vm.hanldeModalType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }