<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '10px' }
          : { 'padding-bottom': '10px' },
      ]"
      class="body-container"
    >
      <v-container v-if="serviceInfo" class="px-10 px-md-auto pb-md-0">
        <div>
          <div class="text-h2 text-md-h1 font-weight-bold mx-0">
            {{ serviceInfo.name }}
          </div>
          <div class="flex-row mb-4 ml-md-1">
            <span
              class="mr-sm-8 body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <span>
                {{ "Proveedor: " + "&nbsp;" }}
              </span>
              <router-link
                class="black--text text-decoration-underline"
                style="color: inherit"
                :to="getProviderRoute"
              >
                {{ providerName }}
              </router-link>
            </span>
            <span
              class="mr-sm-8 text-body-1 text-sm-h4 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon class="mr-2 secondary--text" small> fa-map-pin </v-icon>
              <span style="color: inherit">
                {{ serviceInfo.city }}, {{ serviceInfo.state }}
              </span>
            </span>

            <span
              v-if="serviceInfo.serviceComment.length >= 1"
              class="mr-sm-8 text-subtitle-1 text-sm-h6 font-weight-regular d-flex d-sm-inline-flex"
              style="color: inherit"
            >
              <v-icon class="mr-2 secondary--text" small> fa-star </v-icon>
              <span>
                {{ getReviewAverage() }}
              </span>
              <span class="text-decoration-underline" style="color: inherit">
                ({{ serviceInfo.serviceComment.length }} reseñas)
              </span>
            </span>
          </div>

          <div class="d-inline-block d-sm-flex">
            <Button
              class="my-4 my-sm-0"
              :text="backRoute"
              outlined
              pill
              :height="44"
              prepend-icon="fa-caret-left"
              aria-label="Volver atrás"
              @event="$router.go(-1)"
            />
            <v-spacer></v-spacer>
            <Button
              text="Cotizar"
              :height="
                $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 45 : 50
              "
              aria-label="Desplzar pantalla al formulario de la solicitud de cotización"
              @event="scroll('RequestForm')"
            />
          </div>
        </div>
        <v-divider class="mt-7 mb-14 secondary"></v-divider>
        <div class="my-3">
          <ImageSlideshow
            v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            :images="formatImagesUrl()"
          />
          <ImageSlideshowTwo v-else :images="formatImagesUrl()" />
          <v-divider class="mb-14 secondary"></v-divider>
          <v-row class="mt-5 mb-16">
            <v-col cols="12" sm="6" md="8" class="pr-sm-10 text-body-1">
              <div class="text-h3 text-md-h2 font-weight-bold mb-8">
                Información general
              </div>
              <div class="text-pre-line">
                {{ serviceInfo.description }}
              </div>

              <div class="my-16">
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon
                    v-if="serviceInfo.allCities"
                    class="mr-3 secondary--text"
                    x-large
                  >
                    far fa-check-circle
                  </v-icon>
                  <v-icon v-else class="mr-3 secondary--text" x-large>
                    far fa-times-circle
                  </v-icon>
                  <span v-if="serviceInfo.allCities">
                    Da servicio en otras ciudades.
                  </span>
                  <span v-else> No da servicio en otras ciudades. </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon
                    v-if="serviceInfo.partyPlanner"
                    class="mr-3 secondary--text"
                    x-large
                  >
                    far fa-check-circle
                  </v-icon>
                  <v-icon v-else class="mr-3 secondary--text" x-large>
                    far fa-times-circle
                  </v-icon>
                  <span v-if="serviceInfo.partyPlanner">
                    Trabaja con organizadores de eventos.
                  </span>
                  <span v-else> No trabaja con organizadores de eventos.</span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita {{ serviceInfo.paymentAdvance }}% anticipo para
                    reservar fecha.
                  </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Solicita liquidación del servicio
                    {{ serviceInfo.paymentDeadline }}
                    {{ serviceInfo.paymentDeadline == 1 ? "día" : "días" }}
                    previos al evento.
                  </span>
                </div>
                <div class="my-5 d-flex d-md-block align-center">
                  <v-icon class="mr-3 secondary--text" x-large>
                    far fa-check-circle
                  </v-icon>
                  <span>
                    Reembolso del {{ serviceInfo.reimbursement }}% cancelando
                    {{ serviceInfo.cancellationDeadline }}
                    {{ serviceInfo.cancellationDeadline == 1 ? "día" : "días" }}
                    previos al evento.
                  </span>
                </div>
                <div
                  v-if="
                    serviceInfo.minPeople !== 0 || serviceInfo.maxPeople !== 0
                  "
                  class="my-5 d-flex d-md-block align-center"
                >
                  <v-icon class="mr-3 secondary--text" x-large>
                    fas fa-users
                  </v-icon>
                  <span v-if="serviceInfo.minPeople == 0">
                    Hasta {{ serviceInfo.maxPeople }}
                    {{ serviceInfo.maxPeople === 1 ? "persona." : "personas." }}
                  </span>
                  <span
                    v-else-if="serviceInfo.minPeople == serviceInfo.maxPeople"
                  >
                    {{ serviceInfo.maxPeople }}
                    {{ serviceInfo.maxPeople === 1 ? "persona." : "personas." }}
                  </span>
                  <span v-else>
                    De {{ serviceInfo.minPeople }} a {{ serviceInfo.maxPeople }}
                    {{ serviceInfo.maxPeople === 1 ? "persona." : "personas." }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <QuotationForm
                id="RequestForm"
                :service-id="parseInt(this.$route.params.id)"
                :payment-service-deadline="serviceInfo.paymentDeadline"
                :service-category="serviceInfo.category"
              />
            </v-col>
          </v-row>
          <!-- <div
            class="font-weight-bold my-8"
            :style="[
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? { 'font-size': '22px' }
                : { 'font-size': '31px' },
            ]"
          >
            Preguntas frecuentes
          </div>
          <v-expansion-panels class="mb-16" flat accordion>
            <v-expansion-panel
              v-for="(faq, i) in service.frequentlyAskedQuestions"
              :key="i"
            >
              <v-expansion-panel-header
                expand-icon="fas fa-angle-down"
                class="pl-0"
              >
                {{ faq.question }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ faq.answer }}
              </v-expansion-panel-content>
              <v-divider
                v-if="i !== service.frequentlyAskedQuestions.length - 1"
                class="my-5 secondary"
              ></v-divider>
            </v-expansion-panel>
          </v-expansion-panels>
          <div
            class="font-weight-bold text-center my-8"
            :style="[
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? { 'font-size': '22px' }
                : { 'font-size': '31px' },
            ]"
          >
            <span class="d-inline-block d-sm-inline-flex">
              <v-icon size="27" class="mr-5 secondary--text align-self-center"
                >fa-star</v-icon
              >
              <span class="align-self-center"
                >{{ service.reviewsRating }} - Evaluaciones recientes de
                {{ service.name }}</span
              >
            </span>
          </div>
          <v-container>
            <v-row justify="space-around">
              <v-col
                v-for="(review, i) in service.reviewsToShow"
                :key="i"
                class="text-center"
                cols="12"
                md="2"
              >
                <span class="text-subtitle-1 font-weight-regular">
                  <v-avatar size="80">
                    <img :src="review.userPicture" :alt="review.userName" />
                  </v-avatar>
                  <div class="my-3">
                    {{ review.userName }}
                  </div>
                  <div :title="review.text" class="font-italic line-clamp">
                    {{ review.text }}
                  </div>
                </span>
              </v-col>
            </v-row>
          </v-container>
          <div class="text-center mt-8 mb-16">
            <v-btn
              outlined
              :ripple="false"
              height="49"
              class="rounded-lg secondary--text text-subtitle-1 mb-10"
              @click="handleModalCall('handleReviewsModal', $event)"
            >
              Ver todas las reseñas
            </v-btn>
          </div> -->
          <!-- <div class="text-center mt-16">
            ¿Algo no está bien con este perfil?
            <a
              class="text-decoration-underline font-weight-bold black--text"
              @click="handleReportModal"
              >Reportar perfil</a
            >
          </div> -->
        </div>
      </v-container>
      <v-container v-else>
        <InlineLoading />
      </v-container>
    </div>
    <Footer />
    <!-- <ModalLocation
      :dialog="locationModal"
      :name="service.name"
      :address="service.mainLocationAddress"
      @closeModal="handleLocationModal"
    />
    <ModalOtherLocation
      :dialog="otherLocationsModal"
      :locations="service.otherLocations"
      @closeModal="handleOtherLocationModal"
    />
    <ModalReviews
      :dialog="reviewsModal"
      :name="service.name"
      :reviews="service.reviews"
      @closeModal="handleReviewsModal"
    /> -->
    <!-- <ModalReport
      :dialog="reportModal"
      :motives="reportMotives"
      type="Service"
      @closeModal="handleReportModal"
    /> -->
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import Footer from "@/components/Shared/Footer.vue";
//import ModalLocation from "@/components/Shared/Modal/ModalLocation.vue";
//import ModalOtherLocation from "@/components/Shared/Modal/ModalOtherLocation.vue";
//import ModalReviews from "@/components/Shared/Modal/ModalReviews.vue";
import ImageSlideshow from "@/components/Shared/ImageSlideshow.vue";
import ImageSlideshowTwo from "@/components/Shared/ImageSlideshowTwo.vue";
//import ModalReport from "@/components/Shared/Modal/ModalReport.vue";
import QuotationForm from "@/components/LandingPage/Service/QuotationForm.vue";
import InlineLoading from "@/components/Shared/InlineLoading.vue";
import { GET_SERVICE_INFO_WITH_SCORE } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import Button from "@/components/Shared/Button.vue";

export default {
  name: "Service",
  components: {
    Navbar,
    SearchBar,
    //ModalLocation,
    //ModalOtherLocation,
    //ModalReviews,
    ImageSlideshow,
    ImageSlideshowTwo,
    QuotationForm,
    InlineLoading,
    Button,
    //ModalReport,
    Footer,
  },
  data: () => ({
    serviceInfo: null,
    providerName: null,
    // ! Remove service object when database fetches are implemented, this is used for demonstration purposes only
    service: {
      // reviews: [
      //   {
      //     userPicture: "https://cdn.vuetifyjs.com/images/john.jpg",
      //     userName: "Nombre del usuario",
      //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      //   },
      //   {
      //     userPicture: "https://cdn.vuetifyjs.com/images/john.jpg",
      //     userName: "Nombre del usuario",
      //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      //   },
      //   {
      //     userPicture: "https://cdn.vuetifyjs.com/images/john.jpg",
      //     userName: "Nombre del usuario",
      //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      //   },
      //   {
      //     userPicture: "https://cdn.vuetifyjs.com/images/john.jpg",
      //     userName: "Nombre del usuario",
      //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      //   },
      //   {
      //     userPicture: "https://cdn.vuetifyjs.com/images/john.jpg",
      //     userName: "Nombre del usuario",
      //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At leo auctor adipiscing lectus at leo. ",
      //   },
      // ],
      images: [
        "fondo_proveedor.png",
        "fondo_mainpage.png",
        "centro_mesa_1.jpg",
        "fondo_proveedor.png",
        "fondo_mainpage.png",
        "centro_mesa_1.jpg",
        "fondo_proveedor.png",
        "fondo_mainpage.png",
        "centro_mesa_1.jpg",
      ],

      responseTime: "4 horas",
      // frequentlyAskedQuestions: [
      //   {
      //     question: "¿Pregunta frecuente?",
      //     answer:
      //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue nisl mauris, tincidunt nulla ipsum ultricies venenatis. Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl quis amet tempus auctor fermentum, urna auctor mattis. Tempus vitae venenatis, pellentesque suspendisse. Sagittis nulla leo erat massa arcu arcu faucibus sed. Ante hac mauris est sit duis volutpat dolor. Et ut quam quis vel augue in ac ac. Suspendisse viverra massa tempor augue integer tortor nisi. Diam sit viverra arcu sagittis neque nullam. Est felis viverra elementum tortor tortor fermentum amet, lectus aliquet. Praesent hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam tellus. Diam suspendisse lacus sed molestie.",
      //   },
      //   {
      //     question: "¿Pregunta frecuente?",
      //     answer:
      //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue nisl mauris, tincidunt nulla ipsum ultricies venenatis. Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl quis amet tempus auctor fermentum, urna auctor mattis. Tempus vitae venenatis, pellentesque suspendisse. Sagittis nulla leo erat massa arcu arcu faucibus sed. Ante hac mauris est sit duis volutpat dolor. Et ut quam quis vel augue in ac ac. Suspendisse viverra massa tempor augue integer tortor nisi. Diam sit viverra arcu sagittis neque nullam. Est felis viverra elementum tortor tortor fermentum amet, lectus aliquet. Praesent hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam tellus. Diam suspendisse lacus sed molestie.",
      //   },
      //   {
      //     question: "¿Pregunta frecuente?",
      //     answer:
      //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue nisl mauris, tincidunt nulla ipsum ultricies venenatis. Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl quis amet tempus auctor fermentum, urna auctor mattis. Tempus vitae venenatis, pellentesque suspendisse. Sagittis nulla leo erat massa arcu arcu faucibus sed. Ante hac mauris est sit duis volutpat dolor. Et ut quam quis vel augue in ac ac. Suspendisse viverra massa tempor augue integer tortor nisi. Diam sit viverra arcu sagittis neque nullam. Est felis viverra elementum tortor tortor fermentum amet, lectus aliquet. Praesent hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam tellus. Diam suspendisse lacus sed molestie.",
      //   },
      //   {
      //     question: "¿Pregunta frecuente?",
      //     answer:
      //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue nisl mauris, tincidunt nulla ipsum ultricies venenatis. Pellentesque leo nunc ac facilisi eu vel feugiat vitae. Nisl quis amet tempus auctor fermentum, urna auctor mattis. Tempus vitae venenatis, pellentesque suspendisse. Sagittis nulla leo erat massa arcu arcu faucibus sed. Ante hac mauris est sit duis volutpat dolor. Et ut quam quis vel augue in ac ac. Suspendisse viverra massa tempor augue integer tortor nisi. Diam sit viverra arcu sagittis neque nullam. Est felis viverra elementum tortor tortor fermentum amet, lectus aliquet. Praesent hendrerit sed amet tortor. Tempus id ultrices commodo tincidunt diam tellus. Diam suspendisse lacus sed molestie.",
      //   },
      // ],
      // reviewsRating: 4.9,
      // reviewsToShow: [],
    },
    // locationModal: false,
    // otherLocationsModal: false,
    // reviewsModal: false,
    // reportModal: false,
    // reportMotives: [
    //   { name: "Se hace pasar por otra persona", value: "falseImpersonation" },
    //   {
    //     name: "Su conducta es ofensiva",
    //     value: "offensiveConduct",
    //   },
    //   { name: "Otro motivo", value: "anotherMotive" },
    // ],
    backRoute: null,
  }),
  computed: {
    getProviderRoute() {
      return {
        name: "Provider",
        params: {
          id: this.serviceInfo.providerId,
        },
      };
    },
  },
  created() {
    this.fetchSingleServiceInfo();
    // ! Remove 'for' when database fetches are implemented, this is used for demonstration purposes only
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    handleOtherLocationModal() {
      this.otherLocationsModal = !this.otherLocationsModal;
    },
    handleLocationModal() {
      this.locationModal = !this.locationModal;
    },
    handleReviewsModal() {
      this.reviewsModal = !this.reviewsModal;
    },
    handleReportModal() {
      this.reportModal = !this.reportModal;
    },
    handleModalCall(functionName, event) {
      this[functionName](event);
    },
    formatImagesUrl() {
      return this.serviceInfo.serviceImage.map((image) => image.url);
    },

    async fetchSingleServiceInfo() {
      const serviceId = parseInt(this.$route.params.id);
      const { data, errors } = await useQuery(GET_SERVICE_INFO_WITH_SCORE, {
        id: serviceId,
      });

      if (data) {
        this.serviceInfo = data.service;
        this.providerName = data.service.provider.name;
      } else if (errors) {
        console.log(errors);
      }
    },

    getReviewAverage() {
      let score = 0;

      if (this.serviceInfo.serviceComment.length > 0) {
        score = this.serviceInfo.serviceComment
          .map((item) => item.score)
          .reduce((prev, curr) => prev + curr, 0);
        score = score / this.serviceInfo.serviceComment.length;
      }

      return score;
    },

    getImages() {},
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.includes("servicios")) {
        vm.backRoute = "Volver a servicios";
      } else if (from.path.includes("proveedor/")) {
        vm.backRoute = "Volver a proveedor";
      } else {
        vm.backRoute = "Volver";
      }

      next();
    });
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.box-card-shadow {
  box-shadow: 4px 2px 14px 0px rgba(0, 0, 0, 0.35);
}
</style>
