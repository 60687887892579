<template>
  <v-card outlined class="pa-8 rounded-lg box-card-shadow">
    <div class="text-h3 text-md-h2 font-weight-bold mb-8">
      Solicita tu cotización
    </div>
    <div>
      <v-form id="request-form" @submit.prevent="submit">
        <div v-for="(quotationItem, i) in quotationRequestItems" :key="i">
          <v-text-field
            v-if="quotationItem.type == 'textfield'"
            v-model="quotationItem.model"
            flat
            dense
            outlined
            required
            hide-details
            single-line
            autocomplete="null"
            color="secondary"
            class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
            background-color="transparent"
            :style="[
              quotationItem.model && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            :placeholder="quotationItem.text"
          >
          </v-text-field>
          <v-autocomplete
            v-if="quotationItem.type == 'select'"
            v-model="quotationItem.model"
            :items="quotationItem.options"
            :no-data-text="quotationItem.noDataText"
            :placeholder="quotationItem.text"
            auto-select-first
            single-line
            outlined
            solo
            dense
            flat
            hide-details
            color="secondary"
            item-color="secondary"
            :background-color="
              quotationItem.model ? 'rgba(98, 37, 130, 0.1)' : ''
            "
            autocomplete="null"
            :menu-props="{
              bottom: true,
              closeOnContentClick: true,
              allowOverflow: true,
              offsetY: true,
              rounded: 'md',
              transition: 'slide-y-transition',
            }"
            append-icon="fa-caret-down secondary--text"
            class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
          >
          </v-autocomplete>
          <v-menu
            v-if="quotationItem.type == 'datePicker'"
            v-model="quotationItem.modelMenu"
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="quotationItem.model"
                readonly
                v-bind="attrs"
                outlined
                dense
                class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
                :style="[
                  quotationItem.model && {
                    'background-color': 'rgba(98, 37, 130, 0.1) !important',
                  },
                ]"
                clearable
                clear-icon="fa-times secondary--text"
                placeholder="Fecha del evento"
                hide-details
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="quotationItem.model"
              no-title
              value="date"
              locale="es-ES"
              :min="newUnixDay"
              @input="quotationItem.modelMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-row v-if="quotationItem.type == 'hour-textfield'" no-gutters>
            <v-col cols="7" class="pr-2 pr-sm-3 pr-md-0">
              <v-text-field
                v-model="quotationItem.modelHour"
                v-mask="'##:##'"
                flat
                dense
                outlined
                required
                hide-details
                single-line
                type="text"
                color="secondary"
                class="rounded-lg mb-3 mb-md-6 v-input--is-focused"
                background-color="transparent"
                :style="[
                  quotationItem.modelHour && {
                    'background-color': 'rgba(98, 37, 130, 0.1) !important',
                  },
                ]"
                :placeholder="quotationItem.textHour"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="pl-0 pl-md-3">
              <v-select
                v-model="quotationItem.modelTime"
                :items="quotationItem.options"
                append-icon="fa-caret-down secondary--text"
                hide-details
                single-line
                outlined
                solo
                dense
                flat
                color="secondary"
                class="mb-3 mb-md-6 rounded-lg v-input--is-focused secondary--text text-subtitle-1"
                item-color="secondary"
                :background-color="
                  quotationItem.modelTime ? 'rgba(98, 37, 130, 0.1)' : ''
                "
                :menu-props="{
                  bottom: true,
                  closeOnContentClick: true,
                  allowOverflow: true,
                  offsetY: true,
                  rounded: 'md',
                  transition: 'slide-y-transition',
                }"
                :placeholder="quotationItem.textTime"
              >
                <template v-slot:selection="{ item }">
                  <span class="secondary--text">{{ item }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-textarea
            v-if="quotationItem.type == 'textarea'"
            v-model="quotationItem.model"
            solo
            flat
            dense
            no-resize
            outlined
            required
            hide-details
            :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 4 : 5"
            color="secondary"
            row-height="25"
            class="rounded-lg mb-3 v-input--is-focused"
            background-color="transparent"
            :style="[
              quotationItem.model && {
                'background-color': 'rgba(98, 37, 130, 0.1) !important',
              },
            ]"
            maxlength="255"
            :placeholder="quotationItem.text"
          >
          </v-textarea>
        </div>
        <Button
          text="Solicitar"
          block
          class="my-7"
          aria-label="Abrir ventana del registro de usuario"
          @event="login()"
        />
        <!-- <v-row no-gutters>
          <v-col cols="9" class="pr-2">Tiempo de respuesta</v-col>
          <v-col cols="3" align="right">
            {{ responseTime }}
          </v-col>
        </v-row> -->
      </v-form>
    </div>
    <ModalLoginRegister
      :dialog="dialog"
      :modal-type="modalType"
      :redirect-login="redirectLogin"
      :redirect-register="redirectRegister"
      @closeModal="handleModal"
      @hanldeModalType="hanldeModalType"
    />
  </v-card>
</template>
<script>
import ModalLoginRegister from "@/components/LandingPage/Shared/Modal/ModalLoginRegister.vue";
import Button from "@/components/Shared/Button.vue";
import { convertUnixToYYYYMMDD } from "@/Utils/dateConverter.js";
import { states, getCitiesByState } from "@/Utils/locations.js";
import VueMask from "v-mask";
import Vue from "vue";

Vue.use(VueMask);
export default {
  name: "QuotationForm",
  components: {
    Button,
    ModalLoginRegister,
  },
  props: {
    paymentServiceDeadline: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dialog: false,
    modalType: "",
    newUnixDay: "",
    servicePaymentDeadline: "",
    // !  Add correct redirects, quotation next step
    redirectLogin: "UserMainPage",
    redirectRegister: "UserMainPage",
    quotationRequestItems: [
      { text: "Nombre", type: "textfield", model: "" },
      { text: "Correo electrónico", type: "textfield", model: "" },
      { text: "Teléfono", type: "textfield", model: "" },
      {
        text: "Fecha del evento",
        type: "datePicker",
        modelMenu: "",
        model: "",
      },
      {
        textHour: "00:00",
        textTime: "AM",
        type: "hour-textfield",
        modelHour: "",
        modelTime: "",
        options: ["AM", "PM"],
      },
      {
        text: "Estado del evento",
        type: "select",
        model: "",
        options: states,
        noDataText: "Estado no econtrado",
      },
      {
        text: "Ciudad del evento",
        type: "select",
        model: "",
        options: [],
        noDataText: "Seleccione un estado",
      },
      { text: "Dirección del evento", type: "textfield", model: "" },
      {
        text: "Tipo de evento",
        type: "select",
        model: "",
        options: [
          "Ambientación",
          "Banquetes",
          "Carpas",
          "Catering",
          "Diseño de eventos",
          "Entretenimiento",
          "Fotógrafos",
          "Iluminación",
          "Invitaciones",
          "Mesas de dulces",
          "Mobiliario",
          "Música",
          "Pastelerías",
          "Pistas de baile",
          "Recuerdos",
          "Salones de eventos",
          "Video",
        ],
        noDataText: "Tipo de evento no encontrado",
      },
      { text: "Número de invitados", type: "textfield", model: "" },
      { text: "Comentarios", type: "textarea", model: "" },
    ],
  }),
  computed: {
    state() {
      return this.quotationRequestItems[5].model;
    },
  },
  watch: {
    state: {
      handler() {
        this.quotationRequestItems[6].model = "";
        this.quotationRequestItems[6].options = getCitiesByState(
          this.quotationRequestItems[5].model
        );
      },
    },
  },
  created() {
    this.servicePaymentDeadline = this.paymentServiceDeadline;
    this.dateUnixToString();
  },
  methods: {
    // ! Guardar datos de la solicitud, y retomarlos/enviarlos despues de terminar el login/registro del usuario
    login() {
      this.hanldeModalType("login");
      this.handleModal();
    },
    hanldeModalType(type) {
      this.modalType = type;
    },
    handleModal() {
      this.dialog = !this.dialog;
    },
    dateUnixToString() {
      const secondsInDay = 86400;
      const unixDays = parseInt(this.servicePaymentDeadline) * secondsInDay;
      this.newUnixDay = convertUnixToYYYYMMDD(
        unixDays + Math.floor(Date.now() / 1000)
      ).toString();
      return this.newUnixDay;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-background-transparent::before {
  background-color: transparent !important;
}

.box-card-shadow {
  box-shadow: 4px 2px 14px 0px rgba(0, 0, 0, 0.35);
}
</style>
